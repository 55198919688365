import { ForwardedRef, forwardRef } from "react";
import {
  Box,
  HTMLChakraProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { omit } from "lodash";
import { OptionalKeys } from "utils/typescriptUtilis";

type BaseProps = HTMLChakraProps<"span"> &
  Pick<ChakraLinkProps, "isExternal" | "target">;

type ExternalLinkProps = BaseProps & {
  isExternal: true;
  href?: string;
};

export type InternalLinkProps = BaseProps &
  OptionalKeys<NextLinkProps, "href"> & {
    isExternal?: false;
  };

export type LinkProps = ExternalLinkProps | InternalLinkProps;

function Link(props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) {
  const { isExternal, href } = props;

  if (!href) {
    return (
      <Box
        ref={ref}
        as="span"
        {...omit(props, [
          "as",
          "locale",
          "passHref",
          "prefetch",
          "replace",
          "scroll",
          "shallow",
          "target",
          "href",
          "isExternal",
        ])}
      />
    );
  }

  if (isExternal) {
    return <ChakraLink ref={ref} {...props} />;
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    as,
    href: internalHref,
    locale,
    passHref = true,
    prefetch,
    replace,
    scroll,
    shallow,
    ...rest
  } = props;
  /* eslint-enable @typescript-eslint/naming-convention */

  return (
    <NextLink
      {...{ as, href, replace, scroll, shallow, passHref, prefetch, locale }}
      legacyBehavior
    >
      <ChakraLink ref={ref} {...rest} />
    </NextLink>
  );
}

export default forwardRef(Link);
