import { ReactNode } from "react";
import { Collapse, Flex, List, ListItem } from "@chakra-ui/react";

type Props = { children: ReactNode; isCurrent?: boolean; subLinks?: ReactNode };

export default function SideBarLinkItem({
  children,
  isCurrent,
  subLinks,
}: Props) {
  return (
    <ListItem>
      <Flex display="block">{children}</Flex>
      {subLinks && (
        <Collapse in={!!isCurrent} unmountOnExit>
          <List pt={2} pb={4}>
            {subLinks}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
}
