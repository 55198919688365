import { ReactNode } from "react";
import { List } from "@chakra-ui/react";
import SideBarHeading from "components/Layout/SideBar/Heading";

type Props = { children: ReactNode; isFirst?: boolean; heading?: ReactNode };

export default function SideBarSection({ children, isFirst, heading }: Props) {
  return (
    <>
      {heading && <SideBarHeading isFirst={isFirst}>{heading}</SideBarHeading>}
      <List>{children}</List>
    </>
  );
}
