import { ReactNode } from "react";
import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { MdAccountCircle, MdLogout } from "react-icons/md";
import { useLogout } from "utils/auth";
import asyncVoidHandler from "utils/asyncVoidHandler";
import { USER_PROFILE } from "utils/links";
import MenuItemLink from "./MenuItemLink";
import NavBarButton from "./NavBarButton";

type Props = {
  children?: ReactNode;
  label: string | undefined | null;
  image?: string | null;
  isLoading?: boolean;
};

export default function NavBarAccountMenu({
  children,
  label,
  image,
  isLoading,
}: Props) {
  const [logout, { loading: isLoggingOut }] = useLogout();

  return (
    <Menu id="NavBar-account-menu">
      <MenuButton
        as={NavBarButton}
        label={label || " "}
        paddingLeft={3}
        minW="6em"
        fontWeight={200}
        fontSize="1.3em"
        isTruncated
        leftIcon={
          !image && isLoading ? (
            <Spinner opacity={0.7} thickness="3px" />
          ) : (
            <Image
              src={image || undefined}
              boxSize="1.5em"
              borderRadius="full"
              fallback={
                <Box
                  width="1.5em"
                  height="1.5em"
                  background="magenta.veryDark"
                  borderRadius="full"
                />
              }
              alt="User profile picture"
            />
          )
        }
      />
      <MenuList color="magenta.veryDark">
        {children}
        <MenuItem
          as={MenuItemLink}
          href={USER_PROFILE}
          icon={<MdAccountCircle />}
        >
          User Profile
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={asyncVoidHandler(logout)}
          disabled={isLoggingOut}
          icon={isLoggingOut ? <Spinner size="xs" /> : <MdLogout />}
        >
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
