import { ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import ErrorBoundary from "components/Layout/ErrorBoundary";
import { CONTENT_FULL_HEIGHT, NAVBAR_HEIGHT } from "./constants";

type Props = Partial<BoxProps> & {
  children: ReactNode;
  isFullHeight?: boolean;
  isWithNavBar?: boolean;
};

export default function BaseContentContainer({
  children,
  isFullHeight,
  isWithNavBar,
  ...rest
}: Props) {
  return (
    <>
      <Box
        minHeight={isWithNavBar ? CONTENT_FULL_HEIGHT : "100vh"}
        height={isFullHeight ? "100%" : undefined}
        {...rest}
      >
        <ErrorBoundary isWithNavBar={isWithNavBar}>{children}</ErrorBoundary>
      </Box>

      {isWithNavBar && (
        <style jsx global>{`
          body {
            padding-top: ${NAVBAR_HEIGHT} !important;
          }
        `}</style>
      )}

      {isFullHeight && (
        <style jsx global>{`
          html,
          body,
          #__next {
            height: 100%;
          }
        `}</style>
      )}
    </>
  );
}
