import { ForwardedRef, forwardRef } from "react";
import { Box, Button, ButtonProps, SystemStyleObject } from "@chakra-ui/react";

const activeStyles = { fontWeight: 400, background: "transparent" };

type NavBarButtonProps = Omit<ButtonProps, "children"> & {
  activePlaceholderProps?: SystemStyleObject;
  label: string;
};

function NavBarButton(
  { activePlaceholderProps, isTruncated, label, ...rest }: NavBarButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      ref={ref}
      borderRadius={0}
      height="100%"
      margin="0 .125em"
      padding=".75em"
      background="transparent"
      fontWeight={300}
      fontFamily="body"
      textTransform="none"
      _hover={activeStyles}
      _focus={activeStyles}
      _active={activeStyles}
      _expanded={activeStyles}
      {...rest}
    >
      <Box
        as="span"
        textAlign="center"
        _after={{
          content: "attr(data-label)",
          display: "block",
          height: 0,
          visibility: "hidden",
          overflow: "hidden",
          userSelect: "none",
          pointerEvents: "none",
          fontWeight: activeStyles.fontWeight,
          ...activePlaceholderProps,
        }}
        isTruncated={isTruncated}
        data-label={label}
      >
        {label}
      </Box>
    </Button>
  );
}

export default forwardRef(NavBarButton);
