import {
  AlertStatus,
  Box,
  Button,
  ToastId,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { captureException } from "@sentry/nextjs";
import { useEffect, useRef } from "react";
import { asyncVoid } from "utils/asyncVoidHandler";
import { useActiveAppAlerts } from "utils/currentUserHooks";
import { LocalStorageKeyJSON } from "utils/localStorage";
import { AlertStatus as AlertStatusEnum } from "__generated__/gql/graphql";

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  position: "top",
  duration: null,
  isClosable: true,
  variant: "subtle",
  containerStyle: { width: "50rem", maxWidth: "75vw" },
};

async function getRenderedBody(body: string | null | undefined) {
  if (!body) return;

  const renderMarkdownModule = await import(
    "components/Shared/MarkdownText/render"
  );
  return renderMarkdownModule.default(body);
}

const statusMap: Record<AlertStatusEnum, AlertStatus> = {
  [AlertStatusEnum.ERROR]: "error",
  [AlertStatusEnum.INFO]: "info",
  [AlertStatusEnum.SUCCESS]: "success",
  [AlertStatusEnum.WARNING]: "warning",
};

export default function useAppAlerts() {
  const alerts = useActiveAppAlerts();

  const toastIdsRef = useRef<ToastId[]>([]);

  const lsRef = useRef(new LocalStorageKeyJSON<string[]>("dismissedAppAlerts"));

  const dismissedAppAlertsRef = useRef(lsRef.current.get() || []);

  const showToast = useToast(DEFAULT_TOAST_OPTIONS);

  useEffect(() => {
    if (alerts) {
      try {
        const toastIds = toastIdsRef.current;
        const dismissedAppAlerts = dismissedAppAlertsRef.current;

        const alertIds = alerts.map((alert) => {
          if (
            !toastIds.includes(alert.id) &&
            !dismissedAppAlerts.includes(alert.id)
          ) {
            asyncVoid(
              getRenderedBody(alert.body).then((renderedBody) => {
                const toastId = showToast({
                  id: alert.id,
                  title: alert.heading,
                  description: (
                    <>
                      {renderedBody || alert.body}

                      <Box textAlign="right">
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            dismissedAppAlerts.push(alert.id);
                            lsRef.current.set(dismissedAppAlerts);
                            showToast.close(alert.id);
                          }}
                        >
                          Don’t show again
                        </Button>
                      </Box>
                    </>
                  ),
                  status: statusMap[alert.status],
                });

                if (toastId) toastIds.push(toastId);
              }),
            );
          }

          return alert.id;
        });

        toastIds.forEach((toastId) => {
          if (typeof toastId === "string" && !alertIds.includes(toastId)) {
            showToast.close(toastId);
          }
        });
      } catch (e) {
        captureException(e);
      }
    }
  }, [alerts, showToast]);
}
