import { ReactNode } from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";
import { PADDING_X } from "./constants";

interface SideBarHeadingProps extends HeadingProps {
  children: ReactNode;
  isFirst?: boolean;
}

export default function SideBarHeading({
  children,
  isFirst,
  ...rest
}: SideBarHeadingProps) {
  return (
    <Heading
      as="h4"
      size="xs"
      color="magenta.dark"
      px={PADDING_X}
      pt={8}
      pb={2}
      mt={5}
      borderTopWidth={1}
      className={isFirst ? "first" : undefined}
      sx={{ "&.first": { mt: 0, pt: 0, borderTop: 0 } }}
      {...rest}
    >
      {children}
    </Heading>
  );
}
