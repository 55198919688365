import { Flex, FlexProps } from "@chakra-ui/react";
import {
  CONTENT_FULL_HEIGHT,
  NAVBAR_HEIGHT,
  SIDEBAR_WIDTH,
} from "components/Layout/constants";

export type SideBarVisibilityProps = {
  isHidden?: boolean;
  isMobileSideBarOpen?: boolean;
};

export type SideBarContainerProps = FlexProps & SideBarVisibilityProps;

export default function SideBarContainer({
  children,
  isHidden = false,
  isMobileSideBarOpen = false,
  ...rest
}: SideBarContainerProps) {
  return (
    <Flex
      as="nav"
      direction="column"
      backgroundColor="cardBackground"
      position="fixed"
      left={0}
      top={NAVBAR_HEIGHT}
      height={CONTENT_FULL_HEIGHT}
      width={SIDEBAR_WIDTH}
      maxWidth="100%"
      zIndex={99}
      py="2rem"
      borderColor="gray.200"
      borderRightWidth={1}
      overflowY="auto"
      id="SideBar"
      data-expanded={isMobileSideBarOpen && !isHidden ? true : undefined}
      transform={{
        base: "translateX(-100%)",
        md: isHidden ? undefined : "none",
      }}
      visibility={{ base: "hidden", md: isHidden ? undefined : "visible" }}
      transition={{
        base: "transform .5s, box-shadow .5s, visibility 0s .5s",
        md: isHidden ? undefined : "none",
      }}
      _expanded={{
        boxShadow: { base: "2xl", md: "none" },
        transform: { base: "translateX(0)", md: "none" },
        visibility: "visible",
        transitionDelay: "0s",
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}
