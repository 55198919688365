import { Flex, FlexProps } from "@chakra-ui/react";
import { NAVBAR_HEIGHT } from "components/Layout/constants";

type Props = FlexProps;

export default function NavBarContainer({ children, ...rest }: Props) {
  return (
    <Flex
      as="nav"
      height={NAVBAR_HEIGHT}
      color="white"
      backgroundColor="magenta.veryDark"
      boxShadow="0 4px 4px rgba(0, 0, 0, 0.1)"
      position="fixed"
      width="100%"
      zIndex={100}
      top={0}
      id="NavBar"
      {...rest}
    >
      {children}
    </Flex>
  );
}
