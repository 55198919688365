import { ForwardedRef, forwardRef } from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ActiveLink, { ActiveLinkProps } from "components/Shared/ActiveLink";

type MenuItemLinkProps = ActiveLinkProps & {
  isDisabled?: boolean;
};

function MenuItemLink(
  { href, isDisabled, children, ...rest }: MenuItemLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <ActiveLink
      ref={ref}
      href={isDisabled ? undefined : href}
      _focus={{ outline: 0 }}
      _hover={{ textDecoration: "none" }}
      _activeLink={{
        backgroundColor: "magenta.dark",
        color: "white",
        _focus: { background: "magenta.dark" },
        _hover: { color: "white" },
      }}
      {...rest}
    >
      {children}

      {rest.isExternal && <ExternalLinkIcon mx="5px" />}
    </ActiveLink>
  );
}

export default forwardRef(MenuItemLink);
