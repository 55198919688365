import { forwardRef, ForwardedRef, ReactNode } from "react";
import type { IconType } from "react-icons";
import { ExternalLinkIcon, Icon } from "@chakra-ui/icons";
import ActiveLink, {
  ActiveLinkProps,
  useIsPathCurrent,
} from "components/Shared/ActiveLink";
import SideBarLinkItem from "./LinkItem";
import { PADDING_X } from "./constants";

export type SideBarLinkProps = ActiveLinkProps & {
  IconComponent?: IconType;
  isDisabled?: boolean;
  subLinks?: ReactNode;
};

function SideBarLink(
  {
    children,
    IconComponent,
    isDisabled,
    href,
    subLinks,
    exactMatch,
    exactPathname,
    ...rest
  }: SideBarLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const stringHref = typeof href === "string" ? href : undefined;
  const isCurrent = useIsPathCurrent(rest.hrefForCurrent || stringHref, {
    exactMatch,
    exactPathname,
  });

  return (
    <SideBarLinkItem subLinks={subLinks} isCurrent={isCurrent}>
      <ActiveLink
        ref={ref}
        href={isDisabled ? undefined : stringHref}
        isCurrent={isCurrent}
        aria-disabled={isDisabled || undefined}
        display="block"
        fontFamily="Gotham"
        fontWeight={300}
        lineHeight="normal"
        py={3}
        px={PADDING_X}
        _activeLink={{
          backgroundColor: "lightPink",
          color: "white",
          _hover: { color: "white" },
        }}
        color="magenta.veryDark"
        isTruncated
        _hover={{ textDecoration: "none" }}
        _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
        {...rest}
      >
        {IconComponent && (
          <Icon
            as={IconComponent}
            opacity={0.7}
            mr={2}
            verticalAlign="text-bottom"
          />
        )}

        {children}

        {rest.isExternal && (
          <ExternalLinkIcon
            ml={1.5}
            fontSize=".8em"
            color="magenta.dark"
            verticalAlign="baseline"
            opacity={0.5}
          />
        )}
      </ActiveLink>
    </SideBarLinkItem>
  );
}

export default forwardRef(SideBarLink);
