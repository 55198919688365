import { ForwardedRef, forwardRef } from "react";
import { useRouter } from "next/router";
import Link, { InternalLinkProps } from "components/Shared/Link";

export type UseIsPathCurrentProps = {
  /** Determines whether the current path must match the provided `href`
   * exactly, including query strings, or if all paths starting with
   * the provided `href` should also be considered active (default). */
  exactMatch?: boolean;
  /** Determines whether the current pathname must match the provided `pathname`
   * exactly, ignoring query strings (default), or if all paths starting with
   * the provided `pathname` should also be considered active. */
  exactPathname?: boolean;
};

function ensureArray(href: string | string[] | null | undefined) {
  if (typeof href === "string") return [href];

  if (href && Array.isArray(href)) return href;

  return href;
}

export function useIsPathCurrent(
  hrefs: string | string[] | null | undefined,
  { exactMatch = false, exactPathname = true }: UseIsPathCurrentProps = {},
) {
  const { asPath: currentHref, pathname } = useRouter();

  const hrefArr = ensureArray(hrefs);

  return hrefArr?.some((href) => {
    if (exactMatch) return currentHref === href;

    if (exactPathname) {
      const pathnameFromHref = currentHref.replace(/([^?]+)(\?.*)$/, "$1");
      return pathname === href || pathnameFromHref === href;
    }

    return href?.startsWith("/") && currentHref.startsWith(href);
  });
}

export type ActiveLinkProps = Omit<InternalLinkProps, "href"> &
  UseIsPathCurrentProps & {
    href: string | null | undefined;
    /** If provided, this `href` will be used instead of the actual `href` when
     * performing the 'is current path' check. */
    hrefForCurrent?: string | string[];
    /** If provided, this component will bypass checking whether the link is
     * current and will operate in a 'controlled' manner based on this prop. */
    isCurrent?: boolean;
  };

function ActiveLink(
  {
    exactMatch = false,
    exactPathname = true,
    href,
    hrefForCurrent,
    isCurrent: isCurrentProp,
    ...rest
  }: ActiveLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const isCurrent = useIsPathCurrent(hrefForCurrent || href, {
    exactMatch,
    exactPathname,
  });
  return (
    <Link
      ref={ref}
      href={href || undefined}
      aria-current={
        (typeof isCurrentProp === "boolean" ? isCurrentProp : isCurrent)
          ? "page"
          : undefined
      }
      {...rest}
    />
  );
}

export default forwardRef(ActiveLink);
